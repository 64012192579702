<template>
  <section class="page page-search-result">
    <div class="wrapper">
      <div class="breacrumbs">
        <router-link to="/">Главная</router-link>
        <span class="separator">/</span>
        <span class="current">Результат поиска</span>
      </div>
      <div class="page-title">
        <h1>Вот что мы нашли</h1>
      </div>
      <div class="search-result-list">
        <ul class="list">
          <div
            v-for="taskLink in searchItems"
            v-bind:key="taskLink.id"
            class="item"
          >
            <p>
              <router-link :to="link(taskLink)"
                >{{ taskLink.title }}
              </router-link>
            </p>
          </div>
          <div v-if="searchItems.length === 0" class="item">
            <p>
              Подходящей статьи не найдено. Попробуйте изменить параметры
              поиска.
            </p>
          </div>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SearchResult",

  computed: {
    searchItems() {
      return this.$store.getters.GET_SEARCH_RESULT;
    }
  },
  methods: {
    link: function(data) {
      return "/module" + "/" + data.moduleCode + "/" + data.taskLink;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "/public/template/scss/page-search-result.scss";
</style>
